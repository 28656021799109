import * as React from "react"
import {Link} from "gatsby"
import {CTA} from "./index";
import {Helmet} from "react-helmet";

import '../styles.scss';

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>Nem található</title>
            </Helmet>
            <main>
                <div className="container">

                    <h1>
                        Az álláshirdetések nem itt vannak...
                    </h1>
                    <CTA/>
                    <Link className="center" to="/">Vissza a kezdőoldalra</Link>
                </div>
            </main>
        </>
    )
}

export default NotFoundPage
